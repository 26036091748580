<template>
  <div class="potential" >
    <div ref="navBar">
          <rx-nav-bar title="潜房" androidOrIOSFlag="true" returnPage="test" ></rx-nav-bar>
    </div>
    <van-tabs @click="clickTab" v-model="active" line-width="20" line-height="5" title-active-color="rgb(255,81,45)" swipeable sticky :offset-top="offsetTop" @scroll="scroll" style="bottom: 50px">
      <van-tab title="私盘" >
        <!-- 私盘 -->
        <div class="head">
          <van-row style="padding:10px 10px 0 10px">
            <form action="/">
              <van-search v-model="inputValue" placeholder="房源地址/业主姓名/手机号" shape="round" background="rgb(250,250,250)" @search="onSearchPri"/>
            </form>
          </van-row>
          <div class="downMenu" v-if="statePriList[ProductActive]" @click="isShow = !isShow" id="downList">
            <span>{{statePriList[ProductActive].text}}</span>
            <div class="triangleUp" v-if="!isShow" ></div>
            <div class="triangleDown" v-if="isShow"></div>
          </div>
<!--          <div class="downMenuDetail" v-show="isShow">-->
<!--            <div  class="stateChange" @click="changeType(i)" v-for="(item , i) in statePriList"  :key="i">{{item.text}}</div>-->
<!--          </div>-->
        </div>
        <div class="zw"></div>
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" :finished-text="isEmptuyFlag ? '' : '已经到底了'" @load="this.onLoadPri">
          <!-- 循环列表 -->
          <div class="panel" v-for="(item,i) in orders" :key="i">
            <div class="state" v-if="item.resourceStatus =='1'">有效</div>
            <div class="stateInvalid " v-if="item.resourceStatus =='2'">无效</div>
            <div class="stateSign " v-if="item.resourceStatus =='3'">已签</div>

            <div class="title">
                <span v-if="item.estateRoom_id" class="isBind-houses"></span>
              <p class="panelInfoTitle"  :class="item.resourceStatus == '2'?'faild':null">{{item.address}}</p>
              <!--拨打电话-->
              <div class="relation" @click="showMobile({row:item})"></div>
            </div>
            <div :class="item.resourceStatus== '2'?'faild panelInfo':'panelInfo'" @click="privatePotentialHouseDetailClick({row:item})">
              <div :class="item.resourceStatus == '2'?'faild':'applyState'">
<!--           申请未通过     -->
                <p class="notPass" v-if="item.houseTypeCode=='3'">收房{{item.houseType}}</p>
<!--                收房申请通过 -->
                <p class="pass" v-if="item.houseTypeCode=='2'">收房{{item.houseType}}</p>
<!--                未申请收房-->
                <p class="notApply" v-if="item.houseTypeCode=='0'">{{item.houseType}}收房</p>
<!--                申请中-->
                <p class="pass" v-if="item.houseTypeCode=='1'">收房{{item.houseType}}</p>
              </div>
              <p v-if="item.houseType=='申请通过'">指导价：
                <span >{{item.guidancePrice ? item.guidancePrice + '元': '暂无'}}</span>
              </p>
              <p>租金：<span>{{item.rentMoney != undefined ? item.rentMoney + '元': '暂无'}}</span></p>
              <p>备注：<span>{{item.remarks ? item.remarks : '暂无'}}</span></p>
            </div>
            <van-row v-if="item.fuaddStaff_id" class="order_details" type="flex" justify="space-between">
              <van-col span="2">
                <div class="bgPoint">
                  <div class="topVertical"></div>
                  <div class="outerCircle">
                    <div class="insideCircle"></div>
                  </div>
                  <div class="bottomVertical"></div>
                </div>
              </van-col>
              <van-col span="17" >
                <p class="followInfo">
                  <span class="follow">跟进</span>
                  <span class="time">{{item.fuaddTime}}</span>
                </p>
                <p class="followDetail" style="marginBottom:15px"><span>「 {{item.fuaddStaff}} 」</span>{{item.followUpDetail}}</p>
              </van-col>
              <van-col span="5" class="btnWrapper" v-if="checkAuthList(authButtonsList,'followUp')">
                <button  class="moreBtn" @click="moreFollowUpClick( {row:item} )">更多跟进</button>
              </van-col>
            </van-row>
            <div v-if="item.resourceStatus !='2'&&item.houseTypeCode!='2'&&item.houseTypeCode!='1' && checkAuthList(authButtonsList,'applicationRoom')" class="footer">
              <button class="lastBtn" @click="houseApplyClick({row :item})">申请收房</button>
            </div>
          </div>
        </van-list>
              <!--空状态显示-->
              <common-empty v-if="isEmptuyFlag"></common-empty>
      </van-pull-refresh>
      </van-tab>

      <van-tab title="公盘" >
        <div class="head">
          <van-row style="padding:10px 10px 0px 10px">
            <form action="/">
              <van-search
                      v-model="inputValue"
                      placeholder="房源地址/业主姓名/手机号"
                      shape="round"
                      background="rgb(250,250,250)"
                      @search="onSearchPlc"
              />
            </form>
          </van-row>
          <div class="downMenu" v-if="stateList[ProductActivePlc]" @click="isShowPlc = !isShowPlc" id="downListPlc">
            <span>{{stateList[ProductActivePlc].text}}</span>
            <div class="triangleUp" v-if="!isShowPlc" ></div>
            <div class="triangleDown" v-if="isShowPlc"></div>
          </div>
<!--          <div class="downMenuDetail" v-show="isShowPlc">-->
<!--            <div  class="stateChange" @click="changeTypePlc(i)" v-for="(item , i) in stateList"  :key="i">{{item.text}}</div>-->
<!--          </div>-->
        </div>
        <div class="zw"></div>
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
                v-model="loadingPlc"
                :finished="finishedPlc"
                :finished-text="isEmptuyFlag ? '' : '已经到底了'"
                @load="this.onloadPlc"
        >
          <div class="panelTwo" v-for="item in  ordersPlc" :key="item.id" >
            <div class="panelTwoTitle">
              <span class="titleOne">{{ item.houseCount}} </span>
              <span class="titleTwo"> 套</span>
              <span class="titleThree">.</span>
              <span class="titleFour">{{ item.ownerName }}</span>
              <img src="../../../assets/images/tel.png" @click="showMobile({row:item})"/>
            </div>
            <div class="ruleOne"></div>
            <div class="panelTwoInfo" @click="publicPotentialHouseDetailClick({row:item})">
              <span v-if ="item.resourceStatus==1" class="infoStatePass">有效</span>
              <span v-if ="item.resourceStatus==2" class="infoStateInvalid">无效</span>
              <span v-if ="item.resourceStatus==3" class="infoStateSigned">已签</span>
              <div v-if ="item.resourceStatus==1"  class="infoDotPass">.</div>
              <div v-if ="item.resourceStatus==2"  class="infoDotInvalid">.</div>
              <div v-if ="item.resourceStatus==3"  class="infoDotSigned">.</div>
              <span class="infoDetail">
                {{ item.address }}
              </span>
            </div>
            <div class="panelTwoDetail">
              <span class="infoRental"> 租金：</span>
              <span class="infoRentalVal">
                {{ item.rentMoney ? (item.rentMoney + '元/月') : '暂无'}}
                </span>
            </div>
          </div>
        </van-list>
              <common-empty v-if="isEmptuyFlag"></common-empty>
          </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <!--拨打电话弹窗-->
    <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName" :dialogTitle="dialogTitle" :mobileShow="isMobileShow" @hideDialog="hideDialog"> </dial-mobile>

    <div class="more_wrapper" v-if="checkAuthList(authButtonsList,'add')">
      <van-button type="primary" icon="plus" size="large" class="more_house" @click="saveHouseResource">添加潜房</van-button>
    </div>
    <van-popup v-model="isShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="statePriList"
          @cancel="isShow = false"
          :default-index="ProductActive"
          value-key="text"
          @confirm="changeType"
      />
    </van-popup>
    <van-popup v-model="isShowPlc" position="bottom">
      <van-picker
          show-toolbar
          :columns="stateList"
          @cancel="isShowPlc = false"
          :default-index="ProductActivePlc"
          value-key="text"
          @confirm="changeTypePlc"
      />
    </van-popup>
<!--      <meta name=“viewport” content=“width=device-width , viewport-fit=cover”>-->
<!--      <meta name="viewport" content="initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, width=device-width,viewport-fit=cover">-->
  </div>
</template>
<script>
  import { NavBar, Tab, Tabs, Search, Row, Col, DropdownMenu, DropdownItem, Tag, Button,Overlay ,Popup,Field,List,Toast,PullRefresh,Picker   } from 'vant'
  import {
    queryPotentialResources,getStaffFuntionModelList
  } from "../../../getData/getData";
  import {getStaffId, responseUtil,checkAuthList} from "../../../libs/rongxunUtil";
  import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile';
  import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
  export default {
    data () {
      return {
          //空状态图
          isEmptuyFlag : false,
          isLoading : false,
        follow:{},
        offsetTop : '48',
        isShow : false,
        isShowPlc : false,
        // 公有的分页
        firstLoadPlc : '1',
        pagePlc : 1,
        loadingPlc : false,
        finishedPlc : false,
        countPlc : 0,
        selected : true,
        ProductActivePlc : 1,
        ProductActive : 1,
        flagPlc : false,
        //分页
        firstLoadPri: '0',
        val : '',
        loading: false,
        finished: false,
        count:0,
        page:1,
        flag:false,
        //拨打电话弹窗
        isMobileShow:false,
        temporaryOwnerMobile:'',
        temporaryOwnerName:'',
        dialogTitle:'联系业主',
        //获取tab列表值临时存储
        tabName:'',
        invalidMessage:'',
        active: 0,
        inputValue: '',
        // inputValue:{},
        state: '有效',
        stateList: [
          { text: '全部潜房', value: '0' },
          { text: '有效', value: '1' },
          { text: '无效', value: '2' }
        ],
        statePri : '有效',
        statePriList : [
          { text: '全部潜房', value: '0' },
          { text: '有效', value: '1' },
          { text: '无效', value: '2' },
          { text: '已签', value: '3' },
          { text: '0跟进', value: '4' },
          { text: '未实勘', value: '5' },
          { text: '收房中', value: '6' },
        ],
        //私盘的列表
        orders: [
        ],
        ordersPlc: [
        ],
        maintainer:0,
        houseId : [],
        authButtonsList:[],
      }
    },
    name: 'PotentialHouseList',
    components: {
      [NavBar.name]: NavBar,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Search.name]: Search,
      [Row.name]: Row,
      [Col.name]: Col,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [Tag.name]: Tag,
      [Button.name]: Button,
      [Overlay.name]:Overlay,
      [Popup.name]:Popup ,
      [Toast.name]:Toast ,
      [Field.name]:Field,
      [List.name]:List,
      [PullRefresh.name]:PullRefresh,
      dialMobile,
      rxNavBar,
      [Picker.name]: Picker
    },
      beforeRouteEnter(to, from, next) {
          //清除本地localStorage缓存
          if(from.name == null){
              localStorage.removeItem('currentLabel');
              localStorage.removeItem('type');
              localStorage.removeItem('inputValue');
              localStorage.removeItem('currentLabelPublic');
          }
          next()
      },
    created  : function() {
        // 权限获取
        this.getStaffFuntionModelList()
        //接收工作台传递的参数,如果存在，保存至本地
        if(this.$route.query.currentLabel){
        localStorage.setItem('currentLabel',JSON.stringify(this.$route.query.currentLabel))
        }
      //判断私盘或公盘的tab
        if(localStorage.getItem('type')){
            let activeCache = JSON.parse(localStorage.getItem('type'))
            this.active = Number(activeCache)
        }
        //判断私盘或公盘的下拉缓存
        if(localStorage.getItem('currentLabel')){
            let currentLabel = JSON.parse(localStorage.getItem('currentLabel'))
            if(this.active==0){
                this.ProductActive = Number(currentLabel)
            }else{
                this.ProductActivePlc = Number(currentLabel)
            }
        }
        //搜索缓存
        if(localStorage.getItem('inputValue')){
            this.inputValue = JSON.parse(localStorage.getItem('inputValue'))
        }
    },
    methods:{
        //下拉刷新
        onRefresh() {
            if(this.active==0){
                this.orders = [];
                this.page = 1;
                this.loading = false;
                this.finished = false;
                this.loading = true;
                this.isLoading = false;
                this.onLoadPri()
                responseUtil.alertMsg(this,"刷新成功")
            }else{
                this.ordersPlc = [];
                this.pagePlc = 1;
                this.loadingPlc = false;
                this.finishedPlc = false;
                this.loadingPlc = true;
                this.onloadPlc()
                this.isLoading = false;
                responseUtil.alertMsg(this,"刷新成功")
            }
        },
      clickTab(active){
          this.active = active
          localStorage.setItem('type',JSON.stringify(this.active))
          this.inputValue = ''
          if(active==0){
              this.ProductActive = 1
              this.orders = [];
              this.page = 1;
              this.loading = false;
              this.finished = false;
              this.loading = true;
              localStorage.setItem('currentLabel',JSON.stringify(this.ProductActive))
              localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
              this.onLoadPri()
          }else{
              this.ordersPlc = [];
              this.pagePlc = 1;
              this.loadingPlc = false;
              this.finishedPlc = false;
              this.loadingPlc = true;
              this.ProductActivePlc = 1
              localStorage.setItem('currentLabel',JSON.stringify(this.ProductActivePlc))
              localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
              this.onloadPlc()
          }
      },
      getStaffFuntionModelList () {
          var that = this
          let data = {}
          data.staff_id = getStaffId()
          data.menuName = 'house_index'
          getStaffFuntionModelList(data).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                  that.authButtonsList = response.data.data.data
              })
          })
      },
      checkAuthList,
      // 滚动时获取nav高度并赋值
      scroll() {
        this.offsetTop = this.$refs.navBar.clientHeight
      },
      showOrHidden(){
        this.isShow = !this.isShow;
        var sp = document.getElementById("downList");
        if(sp){
          document.addEventListener('click',e =>{
            if(!sp.contains(e.target)){
              this.isShow = false;
            }
          })
        }
      },
      showOrHiddenPlc(){
        this.isShowPlc = !this.isShowPlc;
        var sp = document.getElementById("downListPlc");
        if(sp){
          document.addEventListener('click',e =>{
            if(!sp.contains(e.target)){
              this.isShowPlc = false;
            }
          })
        }
      },
      //拨打电话
      showMobile(e){
        let that = this;
        that.temporaryOwnerMobile = e.row.ownerMobile;
        that.temporaryOwnerName = e.row.ownerName
        that.isMobileShow = !that.isMobileShow;
      },
      //关闭拨打电话弹窗
      hideDialog() {
        let that = this;
        that.isMobileShow = false;
      },
      //申请收房
      houseApplyClick(e){
        this.$router.push({
          name:'ApplicationForRehousing',
          query:{
            id:e.row.id,
            //房源地址
            houseAddress:e.row.address,
            //房间数量
            bedroomCount:e.row.bedroomCount,
            //改造数量
            changHouseNum:e.row.changHouseNum,
            //租金
            rentMoney:e.row.rentMoney,
            //朝向ID
            orientation_id:e.row.orientation_id,
            //朝向
            orientationName:e.row.orientationName,
            //面积
            builtUpArea:e.row.builtUpArea,
            //装修预算
            decorationMoney:e.row.decorationMoney,
            //收房描述
            houseRemarks:e.row.remarks,
            // 栋ID
            estatePedestal_id:e.row.estatePedestal_id,
            //楼盘Id
            estate_id:e.row.estate_id,
            //单元ID
            estateUnit_id:e.row.estateUnit_id,
            //房间ID
            estateRoom_id:e.row.estateRoom_id,
            //
          }
        });
      },
      //设置搜索事件
      onSearchPlc(){
        this.ordersPlc = [];
        this.pagePlc = 1;
        this.loadingPlc = false;
        this.finishedPlc = false;
        this.loadingPlc = true;
          if(this.inputValue!=''){
              localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
          }else{
              localStorage.removeItem('inputValue')
          }
        this.onloadPlc()
      },
      //设置搜索事件
      onSearchPri(){
        this.orders = [];
        this.page = 1;
        this.loading = false;
        this.finished = false;
        this.loading = true;
        if(this.inputValue!=''){
            localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
        }else{
            localStorage.removeItem('inputValue')
        }
        this.onLoadPri()
      },
      // 初始化私盘的方法
      onLoadPri:function(){
        var that = this
        let initData = {}
        initData.staff_id=  getStaffId()
        if(that.inputValue!=''){
          initData.currentPage='1'
        }else {
          initData.currentPage=that.page+''
        }
        initData.numberPage='5'
        initData.resourceBelog='0'
        initData.searchName=that.inputValue
        // console.log('ProductActive'+that.ProductActive)
        if(that.ProductActive=='1'){
          initData.resourceStatus='1'
        }else if(that.ProductActive=='2'){
          initData.resourceStatus='2'
        }else if(that.ProductActive=='3'){
          initData.resourceStatus='3'
        }else if(that.ProductActive=='4'){
          //0跟进
          initData.resourceStatus='1'
          initData.selectSearchType='8'
        }
        else if(that.ProductActive=='5'){
          //未实勘
          initData.resourceStatus='1'
          initData.selectSearchType='9'
        }
        else if(that.ProductActive=='6'){
          //收房中
          initData.resourceStatus='1'
          initData.selectSearchType='10'
        }
        else{
          initData.resourceStatus='0'
        }
        queryPotentialResources(initData).then(function (response) {
          responseUtil.dealResponse(that,response,() => {
              // console.log(response.data.data)
            that.count = response.data.data.pageCount
              if(that.count == 0&&that.page == 1){
                  that.isEmptuyFlag = true
              }else{
                  that.isEmptuyFlag = false
              }
            if(that.count!=0){
              for(let i=0;i<response.data.data.data.length;i++){
                that.orders.push(response.data.data.data[i])
              }
            }
            // 加载状态结束
            that.page++;
            that.loading = false;
            that.firstLoadPri++;
            // 数据全部加载完成
            if (that.orders.length >= that.count) {
              that.finished = true;
            }
          })
        })
      },
      //初始化公盘的方法
      onloadPlc : function(){
        var that=this
        let initPublic={}
        initPublic.staff_id= getStaffId()
        if(that.inputValue!=''){
          initPublic.currentPage='1'
        }else{
          initPublic.currentPage = that.pagePlc + ''
        }
        initPublic.numberPage='5'
        initPublic.resourceBelog='1'
        initPublic.searchName=that.inputValue
        if(that.ProductActivePlc==0){
          initPublic.resourceStatus='0'
        }else if(that.ProductActivePlc==1){
          initPublic.resourceStatus='1'
        }else {
          initPublic.resourceStatus='2'
        }
        queryPotentialResources(initPublic).then(function (response) {
          responseUtil.dealResponse(that,response,() =>{
            that.countPlc = response.data.data.pageCount
              if(that.countPlc == 0&&that.pagePlc == 1){
                  that.isEmptuyFlag = true
              }else{
                  that.isEmptuyFlag = false
              }
            if(that.countPlc!=0){
              for(let i=0;i<response.data.data.data.length;i++){
                that.ordersPlc.push(response.data.data.data[i])
              }
            }
            that.loadingPlc = false;
            that.pagePlc++;
            // 数据全部加载完成
            if (that.ordersPlc.length >= that.countPlc) {
              that.finishedPlc = true;
            }
          })
        })
      },
      //更多跟进
      moreFollowUpClick(e){
        this.$router.push({
          name:'addFollowUp',
          query : {
            id : e.row.prId,
            fun : 'potentialHouse',
            followUpType : '1'
          }
        })
      },
      //私盘点击获取潜房详情路由跳转
      privatePotentialHouseDetailClick(e){
        this.$router.push({
          name:'addPotentialHouse',
          query:{
            houseType:'private',
            idList:e.row.prIdList,
            id:e.row.prId,
          }
        });
      },
      //公盘点击获取潜房详情路由
      publicPotentialHouseDetailClick(e){
        this.$router.push({
          name :'addPotentialHouse',
          query :{
            houseType:'public',
            idList : e.row.prIdList,
            id: e.row.prId,
          }
        })
      },

      //添加房源
      saveHouseResource(){
        this.$router.push({
          name:'RegisterPotentialHouse',
          query:{
            addHouse : 'addHouse'
          }
        });
      },
      //公盘的切换下拉框状态方法
      changeTypePlc(value,index){
        localStorage.setItem('currentLabel',JSON.stringify(index))
        this.ordersPlc = [];
        this.pagePlc = 1;
        this.loadingPlc = false;
        this.finishedPlc = false;
        this.isShowPlc = false;
        this.state = this.stateList[index].text;
        this.ProductActivePlc = index
        this.loadingPlc = true;
        this.onloadPlc()
      },
      //私盘的下拉框
      changeType(value,index){
        localStorage.setItem('currentLabel',JSON.stringify(index))
        this.orders = [];
        this.finished = true;
        this.page = 1;
        this.loading = false;
        this.finished = false;
        this.isShow = false;
        // this.number= index;
        this.statePri = this.statePriList[index].text;
        this.ProductActive = index
        this.loading = true;
        this.onLoadPri()
      }
    }
  }
</script>
<style lang="less" scoped>
  @redius:16px;
  @border:1px solid rgb(244, 244, 244);
  .downMenu{
    width: 92%;
    display: flex;
    margin: -1px auto;
    background-color: #fafafa;
    height: 25px;
    span{
      color: red;
    }
    .triangleUp,.triangleDown{
      width: 0;
      height: 0;
      margin-left: 10px;
      border-width: 4px;
      border-style: solid;
    }
    .triangleUp{
      margin-top: 7px;
      border-color:black transparent transparent transparent;
    }
    .triangleDown{
      margin-top: 3px;
      border-color: transparent transparent black transparent;
    }
  }
  .downMenuDetail{
    padding: 0 15px;
    background-color: #FFFFFF;
    color: #999999;
    div{
      height: 30px;
      line-height: 30px;
    }
  }
  .zw{
    height: 135px;
  }
  .head{
    position: fixed;
    top:95px;
    z-index: 9;
    width: 100%;
  }
  .staffName{
    width: 200px;
  }
  select{
    color: red;
  }
  option{
    color: black;
    height: 30px;
  }
  .potential{
    background-color: rgb(250,250,250);
    font-size: 12px;
    margin-bottom: 44px;
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
    .selectState{
      height: 30px;
      margin-left: 15px;
      border: none;
      background-color: #fafafa;
    }
    .moreBtn{
      width: 80px;
      height: 30px;
      color:rgba(255, 93, 59, 1);
      background-color: rgb(255,255,255);
      font-size: 12px;
      border: 1px solid transparent;
      background-image: linear-gradient(white,white), /* 底色，即原有的背景 */
      linear-gradient(to right,#f5c998, #fe5e3e); /* 模拟渐变边框 */
      background-clip: padding-box, border-box;
      background-origin: border-box;
      border-radius: 8px;
    }
    .line{
      font-size: 20px;
    }
    .bordBottom{
      border-bottom: @border;
    }
    .panel{
      width: 345px;
      margin: 0px auto 15px;
      background-color: rgb(255,255,255);
      border-radius: @redius;
      position: relative;
      font-size: 15px;
      text-align: left;
      &:last-of-type{
        margin-bottom: 0px;
      }
      .state,.stateSign,.stateInvalid{
        border: 1px solid transparent;
        font-size:10px;
        font-weight: bold;
        height:13px;
        line-height: 15px;
        width: 32px;
        border-top-left-radius:7px;
        border-bottom-right-radius: 7px;
        color: rgb(255,255,255);
        text-align: center;
        font-family: sans-serif;
      }
      .state{
        background-image: linear-gradient(to right,#66CB94,#28D3B0 );
      }
      .stateSign{
        background-image: linear-gradient(to right,#0099FF,#0066FF);
      }
      /*无效的状态显示色*/
      .stateInvalid{
        background: #999999;
      }
      .title{
        width: 92%;
        margin: 0 auto;
        font-weight: bolder;
        color: rgb(0,0,0);
        /*<!--border-bottom:@border;-->*/
        font-size: 15px;
        position: relative;
        display: flex;
        justify-content: space-between;
        span{
          font-weight: normal;
          color:rgba(153, 153, 153, 1);
        }
        .relation{
          width: 20px;
          height: 20px;
          background-image: url(../../../assets/images/relation-phone.png);
          background-repeat: no-repeat;
          background-size: cover;
          margin-top: 5px;
          margin-right: 5px;
        }
      }
      .panelInfo{
        /*<!--border-bottom:@border;-->*/
        border-top:1px solid #f4f4f4 ;
        padding: 0 14px 9px;
        font-size: 14px;
        /*line-height: 11px;*/
        p{
          font-weight: bolder;
          /*margin: 15px 0px;*/
          line-height: 24px;
          margin: 0;
          padding: 0;
          .fitment{
            color: #f00;
          }
          span{
            font-weight: normal;
            color: rgba(153, 153, 153, 1);
            font-size: 13px;
            line-height: 24px;
          }
        }
      }
      .order_details{
        padding:0 17px;
        position: relative;
        font-size: 14px;
        border-top:1px solid #f4f4f4 ;
        .follow{
          /*display: inline;*/
          width: 25px;
          height: 15px;
          line-height: 15px;
          background-color: rgb(255,221,220);
          color: rgb(255,91,62);
          font-size: 10px;
          text-align: center;
          border-radius: 25px;
          padding: 2px 6px ;
        }
        .bgPoint{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 15px;
          width: 20px;
          height: 70%;
          .outerCircle{
            height:10px;
            width: 10px;
            border-radius: 50%;
            border:2px solid #FF5D3B;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            .insideCircle{
              border: 3px solid #FF5D3B;
              border-radius: 50%;
            }
          }
          .topVertical{
            height: 100%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to bottom,#FFE4B5 , #FF5D3B);
          }
          .bottomVertical{
            height: 100%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to top,#FFE4B5 , #FF5D3B);
          }
        }
        p{
          padding-left:5px;
          color:rgba(153, 153, 153, 1);
          padding-bottom: 0;
          overflow-wrap: break-word;
        }
        .time{
          padding: 0;
          margin-left: 10px;
        }
        .btnWrapper{
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .followDetail{
          text-indent: 5px;
          font-size: 10px;
          line-height: 15px;
        }
      }
      .footer{
        padding:0 15px;
        height:50px;
        line-height: 50px;
        font-weight: bolder;
        text-align: left;
        display: flex;
        justify-content:space-between;
        align-items: center;
        border-top: 0.02667rem solid #f4f4f4;

        .lastBtn{
          width: 80px;
          height: 30px;
          margin-left: 235px;
          font-size: 12px;
          color: white;
          background-image: linear-gradient(to right,#FFC274,#FF5D3B);
          border: none;
          border-radius: 8px;
          line-height: 30px;
        }

      }
      .house_num{
        font-size: 14px;
        padding:15px;
        margin: 0;
        color: #f40;
      }
    }
    .faild{
      color: rgb(189,189,189);
    }
    .more_wrapper{
      /*width: 100%;*/
      position: fixed;
      bottom:0;
      z-index: 99;
      border: none;
      width: 92%;
      height: 60px;
      padding: 10px 15px;
      /*padding-bottom: env(safe-area-inset-bottom);*/
      background-color: rgb(255,255,255);
      .more_house{
        border-radius: @redius;
        background-color: rgb(72,163,255);
        color: rgb(255,255,255);
        border: none;
      }
    }
    /*收房申请的状态显示*/
    .applyState {
      font-size: 13px;
      margin-top: 10px;
      .notPass{
        color: #F23778;
      }
      .pass{
        color: #26B2B9;
      }
      .notApply{
        color: #A2A2A2;
      }
    }

    /*  公盘按钮*/
    .panelTwo{
      width: 345px;
      margin: 0px auto 15px;
      background-color: rgb(255,255,255);
      border-radius: 8px;
      position: relative;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: left;
      .panelTwoTitle{
        height:20px;
        display: flex;
        align-items: center;
        line-height: 20px;
        margin-left: 15px;
        padding-right: 15px;
        .titleOne{
          color: red;
          font-size: 25px;
          flex: 0.5;
        }
        .titleTwo{
          color: red;
          font-size: 14px;
          margin-top:6px;
          flex: 0.5;
        }
        .titleThree{
          font-size: 25px;
          margin: -11px 3px 0;
          flex: 0.1;
        }
        .titleFour{
          font-weight: bold;
          font-size: 14px;
          margin-top: 6px;
          flex:10;
        }
        img{
          width: 20px;
          height: 20px;
          /*flex: 1;*/
          flex-shrink: 0;
        }
      }
      /*分割线*/
      .ruleOne{
        width: 100%;
        border: 0.5px solid #fafafa;
        margin: 15px auto;
      }
      /*主体信息*/
      .panelTwoInfo{
        width: 92%;
        margin: 0 auto;
        font-size: 14px;
        display: flex;
        align-items: center;
        .infoDotPass ,.infoDotInvalid,.infoDotSigned{
          margin: -6px 3px 0;
          font-weight: bold;
        }
        .infoDotPass{
          color: #00B0B6;
        }
        .infoDotInvalid{
          color: #999;
        }
        .infoDotSigned{
          color: blue;
        }
        /*有效的字体显示状态*/
        .infoStatePass{
          color: rgb(47,210,172);
          margin-top: -1px;
        }
        /*无效的字体显示状态*/
        .infoStateInvalid{
          color: #999;
        }
        /*已签的字体显示状态 infoStatesigned*/
        .infoStateSigned{
          color: blue;
        }

        .infoDetail{
          font-weight: bold;
        }
      }
      /*主体信息详情*/
      .panelTwoDetail{
        width: 92%;
        margin: 10px auto;
        font-size: 14px;
        .infoRental{
          font-weight: bold;
        }
        .infoRentalVal{
          color: #999999;
          font-size: 13px;
        }
      }
    }
    .panelInfoTitle{
      margin: 5px 5px 15px 0;
      width: 80%;
    }
    //是否绑定楼盘字典样式
      .isBind-houses{
          width: 20px;
          height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          background-image: url("https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/79229f2b-a7d8-4385-9113-691512ba50fb.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
      }
  }
</style>
